@font-face {
    font-family: 'Amatic';
    src: local('Amatic'), 
    url('../assets/fonts/amatic/Amatic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    
}

@font-face {
    font-family: 'Lexend';
    src: local('Lexend'), 
    url('../assets/fonts/Lexend/static/Lexend-ExtraLight.ttf') format('truetype');
   font-weight:200;
    font-style: normal;
    
}
.green{
  color: #2D604D !important;

}



.accordion {
    margin-bottom: 10px;
  }
  
  .accordion-title {
    cursor: pointer;
    font-family: 'Amatic', cursive;

    font-size: 40px;
    letter-spacing: 0.1em;
    margin-bottom: 5px;
    text-align:left !important;
   
  }
  
  .accordion-content {
    padding: 10px;
  }
  
  .faq-title{
    text-transform: none !important;

  }

