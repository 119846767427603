@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONTS LINKED.FARM */
@font-face {
    font-family: 'AmaticSC-Bold';
    src: url('../src/assets/fonts/linked.farm/AmaticSC-Bold.woff2') format('woff2'),
         url('../src/assets/fonts/linked.farm/AmaticSC-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lexend';
    src: url('../src/assets/fonts/linked.farm/Lexend/webfonts/Lexend-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lexendbold';
    src: url('../src/assets/fonts/linked.farm/Lexend/webfonts/Lexend-SemiBold.woff2') format('woff2'),
    url('../src/assets/fonts/linked.farm/Lexend/Lexend-VariableFont_wght.ttf ') format('ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'lexendlight';
    src: url('../src/assets/fonts/linked.farm/Lexend/webfonts/Lexend-Light.woff2') format('woff2'),
    url('../src/assets/fonts/linked.farm/Lexend/Lexend-VariableFont_wght.ttf ') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'lexend-ExtraLite';
    src: url('../src/assets/fonts/linked.farm/Lexend/webfonts/Lexend-ExtraLight.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}



.gm-style .gm-style-iw-c {
    
     width: 260px !important;
     width: 260px !important;  
     overflow: hidden;
     
    
     /* transform: translate(-50%,-100%); */
     border-radius: 50px;
     box-shadow: #2D604D 1px 1px 1px;
     /* box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3); */
    
 }

.imgcss{

    background-image:url('./assets/images/bg2.jpg');
    object-fit: contain;
    width: 70px;
    height: 70px;

 }

 .lds-roller {
    display: inline-block;
    position: relative;
    height: 100px;
    width: 100px;
    display: flex;
    justify-items: center;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #2D604D;
    
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  * {
    -webkit-font-smoothing: antialiased;
  }
  

  .logo {
    background: var(--accent);
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./assets/logo/logo.png);
  }
  
 
  
  .languages {
    display: flex;
    flex-direction: column;
    gap: 2px;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 10px;
   box-sizing: border-box;
   

  }
  
  .language {
    cursor: pointer;
    display: block;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 5px;
    color:#2D604D;
  }
  
  .list {
    cursor: pointer;
    display: block;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 9px;
   
  }
  .arrow {

    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
 
  .list1 {
    cursor: pointer;
    display: block;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 5px;
   
  }
  
  .select{cursor: pointer;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 5px;
    color:#2D604D;

  } 

  .divider {
    position: relative;
    background: #D5DCD4;
   
    width:100%;
  }
  
  .triangle {
    position: relative;
    background: #D5DCD4;
   
    width: 100%;
  }
  
  .relative{
    width: 100%;
  }

  .table-row-element{ margin: 0!important ;
    padding-left:0!important ;
    padding-right:0!important ;
  }
